/* You can add global styles to this file, and also import other style files */

html, body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}


.backdropBackgroundBlur {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding-left: 7px !important;
    padding-right: 7px !important;
}

.content {
    padding: 200px 150px 100px !important;
}

@media all and (max-width: 1400px) {
    .content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}